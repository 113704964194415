import React, { FC } from 'react'
import { StatsTileWithGraph } from '@components/Dashboard/Tile'
import { OfferingPieChart } from '@components/Dashboard/Charts/Bar'
import { GridItem } from '@chakra-ui/react'
import { OfferingToPatientObjectType } from '@types'

const TestByOffering: FC<{ data: OfferingToPatientObjectType }> = ({ data }) => {
  const demoOfferingData: OfferingToPatientObjectType = {
    total: 0,
    verified: 0,
    tested: 0,
    inProgress: 0,
    offeringToPatient: {}
  }

  return (
    <GridItem width="100%" maxH="300px">
      {data ? (
        <StatsTileWithGraph
          label={
            <>
              Offerings
              <br />
              {`Total: ${data?.total}`}
            </>
          }
          icon={{ name: 'offering', size: 32 }}
          graph={<OfferingPieChart data={data} />}
        />
      ) : (
        <StatsTileWithGraph
          label={
            <>
              Offerings
              <br />
              {`Total: ${demoOfferingData.total}`}
            </>
          }
          icon={{ name: 'offering', size: 32 }}
          graph={<OfferingPieChart data={demoOfferingData} />}
        />
      )}
    </GridItem>
  )
}

export default TestByOffering
