import { FC } from 'react'
import { ResponsiveBar, BarDatum } from '@nivo/bar'
import { CustomTooltip, AxisTick } from '@components/Dashboard/Charts/Common/Misc'
import { mapTestToColor } from 'pages/index'

interface TestsHistogramProps {
  data: BarDatum[]
  keys: string[]
  indexBy: string
  wrapperWidth: number
}

const TestsHistogram: FC<TestsHistogramProps> = ({ data, keys, indexBy, wrapperWidth }) => {
  return (
    <ResponsiveBar
      data={data}
      colors={({ id }) => {
        const customColor = mapTestToColor(id)
        return customColor
      }}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 24, right: 0, bottom: 32, left: wrapperWidth / 2.5 }}
      padding={0.6}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      enableGridY={false}
      axisTop={{
        renderTick: function ({ tickIndex, x }) {
          let total
          if (data && data[tickIndex] && data[tickIndex].total) {
            total = data[tickIndex].total.toString()
          }
          return (
            <AxisTick
              key={tickIndex}
              x={x}
              y={-20}
              value={total ? total.toString() : '0'}
              dominantBaseline="central"
              textAnchor="middle"
            />
          )
        }
      }}
      axisBottom={{
        renderTick: function (tick) {
          return (
            <AxisTick
              key={tick.tickIndex}
              x={tick.x}
              y={tick.y + 20}
              value={tick.value}
              dominantBaseline="central"
              textAnchor="middle"
            />
          )
        }
      }}
      axisLeft={null}
      enableLabel={false}
      colorBy="id"
      legends={[
        {
          itemTextColor: 'var(--chakra-colors-quredGray-300)',
          dataFrom: 'keys',
          anchor: 'left',
          direction: 'column',
          justify: false,
          translateX: -wrapperWidth / 2.5,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 8,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: 'black'
              }
            }
          ]
        }
      ]}
      role="application"
      ariaLabel="Tests Histogram"
      tooltip={({ id, value, color }) => <CustomTooltip label={`${id}: ${value}`} color={color} />}
    />
  )
}

export default TestsHistogram
