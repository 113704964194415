import { useRef, useState, useEffect, FC } from 'react'
import StatsTileWithGraph from '@components/Dashboard/Tile/StatsTileWithGraph'
import { TestPopulationDivergedBar } from '@components/Dashboard/Charts/Bar'
import { ButtonRow, ButtonItem } from '@components/Dashboard/Charts/Common/TestButtonRow'
import { DashboardGridItem } from '@types'
import { GridItem } from '@chakra-ui/react'
// import { useAppContext } from '@contexts/AppProvider'
import max from 'lodash.max'
import { BarDatum } from '@nivo/bar'

const TestPopulationDivergedBarContainer: FC<DashboardGridItem> = ({ colSpan, rowSpan, data }) => {
  const [activeTestId, setActiveTestId] = useState('')
  const [dataToUse, setDataToUse] = useState([] as BarDatum[])
  // const { demo } = useAppContext()
  const dataItem = {
    MaleColor: 'var(--chakra-colors-quredOrange-500)',
    FemaleColor: 'var(--chakra-colors-quredPurple-500)'
  }

  const items = [] as ButtonItem[]

  data?.data?.population?.forEach(
    (item: { packageId: any; packageName: any; totalOfMale: any; totalOfFemale: any }) => {
      if (item) {
        items.push({
          id: item.packageId,
          testName: item.packageName,
          male: item.totalOfMale || 0,
          female: item.totalOfFemale || 0
        })
      }
    }
  )

  useEffect(() => {
    const newDataToUse: BarDatum[] = []

    if (activeTestId === '') {
      //eslint-disable-next-line
      // @ts-ignore
      data?.data?.allAgeBands?.forEach((ageBand) => {
        newDataToUse.push({
          age: ageBand.code.replace('<', '-'),
          Male: ageBand.male,
          Female: ageBand.female * -1,
          ...dataItem
        })
      })
    } else {
      //eslint-disable-next-line
      // @ts-ignore
      data?.data?.population.forEach((test) => {
        if (test.packageId === activeTestId) {
          //eslint-disable-next-line
          // @ts-ignore
          test.ageBands.forEach((ageBand) => {
            newDataToUse.push({
              age: ageBand.code.replace('<', '-'),
              Male: ageBand.male,
              Female: ageBand.female * -1,
              ...dataItem
            })
          })
        }
      })
    }

    setDataToUse(newDataToUse)
  }, [activeTestId, data])

  const maxValue = max(
    dataToUse.map((item) =>
      Math.max(Math.abs(item.Male as number), Math.abs(item.Female as number))
    )
  ) as number

  const keys = ['Male', 'Female']

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [wrapperWidth, setWrapperWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setWrapperWidth(wrapperRef.current?.clientWidth || 0)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setWrapperWidth(wrapperRef.current?.clientWidth || 0)
  }, [])

  return (
    <GridItem colSpan={colSpan} rowSpan={rowSpan} ref={wrapperRef} width="100%">
      <StatsTileWithGraph
        label="Test Population"
        icon={{ name: 'user', size: 32 }}
        graph={
          <TestPopulationDivergedBar
            data={dataToUse}
            keys={keys}
            indexBy="age"
            wrapperWidth={wrapperWidth / 2}
            maxValue={maxValue}
          />
        }
        graphCustomControl={
          <ButtonRow activeTestId={activeTestId} items={items} setActiveTestId={setActiveTestId} />
        }
      />
    </GridItem>
  )
}

export default TestPopulationDivergedBarContainer
