import React, { forwardRef, ReactElement } from 'react'
import { Flex, Text, Heading } from '@chakra-ui/react'
import { CommonIcon, CommonIconProps } from '@components/Icon/CommonIcon'
import { TrendItem } from '@components/Dashboard/Misc/Trend'
import { Trend } from '@components/Dashboard/Misc'

import uniqueId from 'lodash.uniqueid'

interface Props {
  label: ReactElement | string
  icon: CommonIconProps
  stat?: number | string
  backgroundColor?: string
  trends?: TrendItem[]
  textColor?: string
  graphCustomControl?: ReactElement
  graph: ReactElement | null
  flex?: number
}

const StatsTileWithGraph = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    label,
    icon,
    stat,
    trends,
    backgroundColor = 'white',
    textColor,
    graphCustomControl,
    graph,
    flex = 1
  } = props
  return (
    <Flex
      ref={ref}
      flex={flex}
      p={6}
      display="flex"
      flexDirection="column"
      gap={2}
      h="100%"
      w="100%"
      bg={backgroundColor}
      borderRadius="xl"
      color={textColor}
    >
      <Flex w="100%" alignItems="center" gap={2} mb={2}>
        <CommonIcon name={icon.name} size={icon.size} color={icon.color} />
        <Text fontSize="sm" fontWeight={500}>
          {label}
        </Text>
      </Flex>
      <Flex w="100%" h="100%" justifyContent="space-between" alignItems="center">
        <Heading>{stat}</Heading>
        <Flex w="100%" h="100%">
          {graphCustomControl}
          {graph}
        </Flex>
      </Flex>
      {trends && (
        <Flex w="100%" gap={24} alignItems="flex-end">
          {trends?.map((trend) => (
            <Trend key={uniqueId()} {...trend} />
          ))}
        </Flex>
      )}
    </Flex>
  )
})

StatsTileWithGraph.displayName = 'StatsTileWithGraph'

export default StatsTileWithGraph
