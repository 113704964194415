import React, { useState, useRef, Dispatch, SetStateAction, FC } from 'react'
import {
  Popover,
  Button,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
  Stack,
  Text
} from '@chakra-ui/react'
import { Tag } from '@components/Misc'
import uniqueId from 'lodash.uniqueid'

interface Props {
  dashboardTags: string[]
  selectedTags: string[]
  setSelectedTags: Dispatch<SetStateAction<string[]>>
  preSelectedTags: string[]
  setPreSelectedTags: Dispatch<SetStateAction<string[]>>
}

const AddFiltersPopover: FC<Props> = ({
  dashboardTags,
  preSelectedTags,
  setSelectedTags,
  setPreSelectedTags
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const initialFocusRef = useRef()

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={initialFocusRef.current}
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      placement="bottom-end"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button size="sm" leftIcon={<span aria-hidden>+</span>}>
          Add Filters
        </Button>
      </PopoverTrigger>
      <PopoverContent p={5} width="320px">
        <PopoverArrow />
        <PopoverCloseButton />
        <Flex flexDirection="column" width="100%">
          <Text fontSize="md">Apply Filters</Text>
          <Flex flexWrap="wrap" gap={2} mb={6} mt={6}>
            {dashboardTags?.map((currentTag: string) => {
              const isSelected = preSelectedTags.includes(currentTag)
              return (
                <Tag
                  key={uniqueId(currentTag)}
                  size="sm"
                  color={isSelected ? 'white' : 'quredGray.450'}
                  borderRadius="full"
                  borderColor="var(--chakra-colors-quredGray-450)"
                  backgroundColor={isSelected ? 'quredGray.450' : 'white'}
                  variant="outline"
                  cursor="pointer"
                  onClick={() => {
                    if (isSelected) {
                      const newTags = preSelectedTags.filter(
                        (setecledTag) => setecledTag !== currentTag
                      )
                      setPreSelectedTags(newTags)
                    } else {
                      const newTags = [...preSelectedTags, currentTag]
                      setPreSelectedTags(newTags)
                    }
                  }}
                >
                  {currentTag}
                </Tag>
              )
            })}
          </Flex>
          <Stack spacing={4}>
            <Button
              size="md"
              onClick={() => {
                setSelectedTags(preSelectedTags)
              }}
              disabled={preSelectedTags.length === 0}
            >
              Apply
            </Button>
            <Button
              size="md"
              variant="secondary"
              onClick={() => {
                setSelectedTags([])
                setPreSelectedTags([])
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Flex>
      </PopoverContent>
    </Popover>
  )
}

export default AddFiltersPopover
