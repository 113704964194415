import { FC, useMemo, useCallback, createElement, MouseEvent } from 'react'
import { Bar, BarDatum, BarItemProps } from '@nivo/bar'
import { CustomTooltip, AxisTick, CustomBarSVG } from '@components/Dashboard/Charts/Common/Misc'
import { Flex } from '@chakra-ui/react'
import { useTooltip } from '@nivo/tooltip'

const distanceBetweenBars = 24

interface TestPopulationDivergedBarProps {
  data: BarDatum[]
  keys: string[]
  indexBy: string
  wrapperWidth: number
  maxValue: number | 'auto' | undefined
}

const CustomBarComponent: FC<BarItemProps<BarDatum>> = ({
  bar,
  borderRadius,
  tooltip,
  isInteractive,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isFocusable
}) => {
  const { showTooltipFromEvent, showTooltipAt, hideTooltip } = useTooltip()
  const { key, x, y, width, height, color, data } = bar

  const renderTooltip = useMemo(
    // eslint-disable-next-line react/display-name
    () => () => createElement(tooltip, { ...bar, ...data }),
    [tooltip, bar, data]
  )

  const handleClick = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onClick?.({ color: bar.color, ...data }, event)
    },
    [bar, data, onClick]
  )
  const handleTooltip = useCallback(
    (event: MouseEvent<SVGRectElement>) => showTooltipFromEvent(renderTooltip(), event),
    [showTooltipFromEvent, renderTooltip]
  )
  const handleMouseEnter = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseEnter?.(data, event)
      showTooltipFromEvent(renderTooltip(), event)
    },
    [data, onMouseEnter, showTooltipFromEvent, renderTooltip]
  )
  const handleMouseLeave = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseLeave?.(data, event)
      hideTooltip()
    },
    [data, hideTooltip, onMouseLeave]
  )

  // extra handlers to allow keyboard navigation
  const handleFocus = useCallback(() => {
    showTooltipAt(renderTooltip(), [bar.absX + bar.width / 2, bar.absY])
  }, [showTooltipAt, renderTooltip, bar])
  const handleBlur = useCallback(() => {
    hideTooltip()
  }, [hideTooltip])

  const formattedKey = key.split('.')[0]
  const age = key.split('.')[1]
  let computedX = 0
  const compoutedY = y

  if (formattedKey === 'Female') {
    computedX = x - distanceBetweenBars
  } else if (formattedKey === 'Male') {
    computedX = x + distanceBetweenBars
  }

  return (
    <>
      <CustomBarSVG
        x={computedX}
        y={compoutedY}
        width={width}
        height={height}
        color={color}
        rx={borderRadius}
        onMouseEnter={isInteractive ? handleMouseEnter : undefined}
        onMouseMove={isInteractive ? handleTooltip : undefined}
        onMouseLeave={isInteractive ? handleMouseLeave : undefined}
        onClick={isInteractive ? handleClick : undefined}
        onFocus={isInteractive && isFocusable ? handleFocus : undefined}
        onBlur={isInteractive && isFocusable ? handleBlur : undefined}
      />
      {formattedKey === 'Male' && (
        <AxisTick
          x={computedX - 24}
          y={y + 3}
          value={age}
          dominantBaseline="central"
          textAnchor="middle"
        />
      )}
    </>
  )
}

const TestPopulationDivergedBar: FC<TestPopulationDivergedBarProps> = ({
  data,
  keys,
  indexBy,
  wrapperWidth,
  maxValue
}) => (
  <Flex justifyContent="flex-end" w={wrapperWidth}>
    <Bar
      minValue={typeof maxValue === 'number' ? maxValue * -1 : undefined}
      maxValue={maxValue}
      width={wrapperWidth}
      height={50 + data.length * 20}
      barComponent={(props) => <CustomBarComponent {...props} />}
      isInteractive={true}
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: -12, right: 12, bottom: 0, left: 12 }}
      padding={0.8}
      innerPadding={0}
      borderRadius={2}
      groupMode="stacked"
      layout="horizontal"
      colors={({ id, data }) => String(data[`${id}Color`])}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      axisTop={null}
      axisRight={null}
      enableGridY={false}
      enableGridX={false}
      enableLabel={false}
      axisBottom={null}
      axisLeft={null}
      role="application"
      ariaLabel="Test Population Diverged Bar Chart"
      tooltip={({ id, value, color }) => (
        <CustomTooltip label={`${id}: ${Math.abs(value)}`} color={color} />
      )}
    />
  </Flex>
)

export default TestPopulationDivergedBar
