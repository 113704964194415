import React from 'react'
import { StatsTileWithGraph } from '@components/Dashboard/Tile'
import { NonInteractiveLine } from '@components/Dashboard/Charts/Line'
import { TrendItem } from '@components/Dashboard/Misc/Trend'
import { useAppContext } from '@contexts/AppProvider'
import { GridItem } from '@chakra-ui/react'

const CompletedTests = ({ trends, total }: { trends: TrendItem[]; total: number }) => {
  const { demo } = useAppContext()

  const demoTrends = [
    {
      helperText: 'Today',
      value: 7,
      trend: 'up',
      color: 'white'
    },
    {
      helperText: 'Last Month',
      value: 256,
      trend: 'up',
      color: 'white'
    },
    {
      helperText: 'Last Year',
      value: 2736,
      trend: 'up',
      color: 'white'
    }
  ] as TrendItem[]

  const data = trends
    ? [
        {
          id: 'pastCompanyTests',
          color: 'white',
          data: trends.map((trend) => ({
            x: trend.helperText,
            y: trend.value
          }))
        }
      ]
    : null

  const demoData = [
    {
      id: 'pastCompanyTests',
      color: 'white',
      data: demoTrends.map((trend) => ({
        x: trend.helperText,
        y: trend.value
      }))
    }
  ]

  const stat = demo ? demoTrends.reduce((acc, trend) => acc + (trend.value as number), 0) : total

  return (
    <GridItem width="94%" maxH="300px">
      {data && (
        <StatsTileWithGraph
          label={
            <>
              {' '}
              Tests Completed
              <br />
              (Total)
            </>
          }
          icon={{ name: 'vial', size: 32, color: 'white' }}
          stat={stat}
          backgroundColor="quredBlue.500"
          textColor="white"
          trends={demo ? demoTrends : trends}
          graph={<NonInteractiveLine data={demo ? demoData : data} />}
        />
      )}
    </GridItem>
  )
}

export default CompletedTests
