import React, { FC, ReactElement } from 'react'
import { Flex, Text, Heading } from '@chakra-ui/react'
import { CommonIcon, CommonIconProps } from '@components/Icon/CommonIcon'
import { Trend } from '@components/Dashboard/Misc'

interface Props {
  label: ReactElement | string
  icon: CommonIconProps
  stat: number | string
  helperText?: string
  trend?: 'up' | 'down'
  value?: number | string
  backgroundColor?: string
}

const SimpleStatsTile: FC<Props> = ({
  label,
  icon,
  stat,
  helperText,
  trend,
  value,
  backgroundColor = 'white'
}) => {
  return (
    <Flex p={6} h="100%" w="100%" bg={backgroundColor} borderRadius="xl" flexDirection="column">
      <Flex w="100%" alignItems="center" gap={2} color="quredGray.900" mb={2}>
        <CommonIcon name={icon.name} size={icon.size} color={icon.color} />
        <Text fontSize="sm" fontWeight={500}>
          {label}
        </Text>
      </Flex>
      <Heading>{stat}</Heading>
      <Flex w="100%" h="100%" gap={24} alignItems="flex-end">
        <Trend trend={trend} value={value} helperText={helperText} />
      </Flex>
    </Flex>
  )
}

export default SimpleStatsTile
