import React from 'react'
import { StatsTile } from '@components/Dashboard/Tile'
// import { useAppContext } from '@contexts/AppProvider'

const SignUpTotal = ({ stat }: { stat: number }) => {
  // const { demo } = useAppContext()

  // const demoData = {
  //   stat: 90,
  //   value: 30,
  //   trend: 'up' as const
  // }

  return (
    <StatsTile
      label={
        <>
          Sign Up <br /> (Installed the app)
        </>
      }
      icon={{ name: 'mobile', size: 32 }}
      stat={stat}
      // trend={demo ? demoData.trend : undefined}
      // value={demo ? demoData.value : undefined}
      // helperText="Registrations"
    />
  )
}

export default SignUpTotal
