import { useState } from 'react'
import type { ReactElement } from 'react'
import { AuthLayout } from '@components/Layout'
import type { NextPageWithLayout } from './_app'
import { Dashboard } from '@components/Dashboard'
import { AddFiltersPopover } from '@components/Dashboard/Misc'
import { SectionHeader } from '@components/Misc'
import { Button, Flex, ButtonGroup } from '@chakra-ui/react'
import { Tag } from '@components/Misc'
import uniqueId from 'lodash.uniqueid'
import { useAppContext } from '@contexts/AppProvider'
import useSWR from 'swr'
import { Source } from '@contexts/AxiosProvider'
import { formatDate } from '@utils'

export const mapTestToColor = (testName: string | number): string => {
  switch (testName) {
    case 'Organ & Body Function':
      return 'var(--chakra-colors-quredBlue-500)'
    case 'Vital Vitamins':
      return 'var(--chakra-colors-quredGold-500)'
    case 'Result With Panel':
      return 'var(--chakra-colors-quredGreen-500)'
    case "Men's Health":
      return 'var(--chakra-colors-quredOrange-500)'
    case "Women's Health":
      return 'var(--chakra-colors-quredPink-900)'
    case 'Diabetes':
      return 'var(--chakra-colors-quredLilac-900)'
    default:
      return 'var(--chakra-colors-quredPurple-500)'
  }
}

const Page: NextPageWithLayout = () => {
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [preSelectedTags, setPreSelectedTags] = useState<string[]>([])
  const { orgId } = useAppContext()
  const { demo, setDemo, isSuperAdmin } = useAppContext()
  const usersInsightsURL = `/analytics/v2/users?organizationId=${orgId}&tags=${selectedTags.join(
    ','
  )}`
  const testsInsightsURL = `/analytics/v2/packages?organizationId=${orgId}&tags=${selectedTags.join(
    ','
  )}`
  const populationInsightsURL = `/analytics/v2/population?organizationId=${orgId}&tags=${selectedTags.join(
    ','
  )}`
  const companyUrl = `/companies/v2/by-fhir/${orgId}/`

  const { data: userInsights } = useSWR(orgId ? [usersInsightsURL, Source.ACCOUNT_V2] : null)
  const { data: testInsights } = useSWR(orgId ? [testsInsightsURL, Source.ACCOUNT_V2] : null)
  const { data: populationInsights } = useSWR(
    orgId ? [populationInsightsURL, Source.ACCOUNT_V2] : null
  )

  const { data: company } = useSWR(orgId ? [companyUrl, Source.CONFIG_V2] : null)
  const aggregateData = (testInsights: any) => {
    let thisMonthValue = 0
    let lastMonthValue = 0
    let yearToDateValue = 0

    if (testInsights?.data && testInsights?.data.monthly) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth()

      const startOfYear = new Date(currentYear, 0, 1)
      const endOfLastMonth = new Date(currentYear, currentMonth, 0)

      testInsights.data.monthly.forEach(({ month, total }: any) => {
        const dataDate = new Date(month)
        const dataYear = dataDate.getFullYear()
        const dataMonth = dataDate.getMonth()

        if (dataYear === currentYear) {
          if (dataMonth === currentMonth) {
            thisMonthValue = total
          } else if (dataMonth === currentMonth - 1) {
            lastMonthValue = total
          }

          if (dataDate >= startOfYear && dataDate <= endOfLastMonth) {
            yearToDateValue += total
          }
        }
      })
    }

    const aggregatedData = [
      { helperText: 'This month', value: thisMonthValue, color: 'white' },
      { helperText: 'Last month', value: lastMonthValue, color: 'white' },
      { helperText: 'Year-To-Date', value: yearToDateValue, color: 'white' }
    ]
    return aggregatedData
  }

  const testsByProfile =
    testInsights && testInsights.data && testInsights.data.monthly
      ? testInsights.data.monthly.reduce((acc: any, { month, packages, total }: any) => {
          const newObj = {
            month: formatDate(new Date(month), { month: 'short' }),
            total: total
          }
          packages.forEach(({ packageName, count }: { packageName: string; count: number }) => {
            //eslint-disable-next-line
            // @ts-ignore
            newObj[packageName] = count
            //eslint-disable-next-line
            // @ts-ignore
            newObj[`${packageName}Color`] = mapTestToColor(packageName)
          })
          return [...acc, newObj]
        }, [])
      : []

  const dashboardData = {
    userInsights,
    testInsights,
    completedTests: aggregateData(testInsights),
    populationInsights,
    testsByProfile
  }

  return (
    <>
      <SectionHeader title="dashboard" icon="dashboard">
        <Flex flexWrap="wrap" gap={1} ml="auto" mr={4}>
          {selectedTags.map(
            (tag: string) =>
              tag && (
                <Tag
                  key={uniqueId(tag)}
                  size="sm"
                  color="quredGray.450"
                  borderRadius="full"
                  borderColor="var(--chakra-colors-quredGray-300)"
                  backgroundColor="transparent"
                  variant="outline"
                  closeButton
                  onClick={() => {
                    const newTags = selectedTags.filter((selectedTag) => selectedTag !== tag)
                    setPreSelectedTags(newTags)
                    setSelectedTags(newTags)
                  }}
                >
                  {tag}
                </Tag>
              )
          )}
          {selectedTags && selectedTags.length > 0 && (
            <Button
              variant="ghost"
              size="xs"
              colorScheme="gray"
              onClick={() => {
                setPreSelectedTags([])
                setSelectedTags([])
              }}
            >
              Clear All
            </Button>
          )}
        </Flex>
        <ButtonGroup size="sm" alignSelf="center">
          <AddFiltersPopover
            preSelectedTags={preSelectedTags}
            setPreSelectedTags={setPreSelectedTags}
            dashboardTags={company?.tags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
          {isSuperAdmin && (
            <Button variant={demo ? 'solid' : 'outline'} onClick={() => setDemo(!demo)}>
              {demo ? 'Deactivate Demo' : ' Activate Demo'}
            </Button>
          )}
        </ButtonGroup>
      </SectionHeader>

      <Dashboard data={dashboardData} />
    </>
  )
}

Page.getLayout = function getLayout(page: ReactElement) {
  return <AuthLayout>{page}</AuthLayout>
}

export default Page
