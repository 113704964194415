import React, { FC } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Box, Flex, Text } from '@chakra-ui/react'
import { OfferingToPatientObjectType } from '@types'

const OfferingPieChart: FC<{ data: OfferingToPatientObjectType }> = ({ data: graphData }) => {
  const data = Object.entries(graphData?.offeringToPatient).map(([id, value]) => ({ id, value }))

  const colors = [
    'var(--chakra-colors-quredBlue-500)',
    'var(--chakra-colors-quredGold-500)',
    'var(--chakra-colors-quredOrange-500)',
    'var(--chakra-colors-quredGreen-500)',
    'var(--chakra-colors-quredPink-900)',
    'var(--chakra-colors-quredLilac-900)',
    'var(--chakra-colors-quredPurple-500)',
    'var(--chakra-colors-quredLilac-500)',
    'var(--chakra-colors-quredPink-500)',
    'var(--chakra-colors-quredGray-450)'
  ]

  return (
    <Flex justifyContent="flex-end">
      <Box boxSize={'xl'} flex={1}>
        <ResponsivePie
          data={data}
          margin={{ top: 20, right: 80, bottom: 380, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          colors={colors}
          arcLabelsTextColor="white"
          animate
        />
      </Box>

      <Box boxSize={'sm'} flex={1}>
        {data?.map((entry, index) => (
          <Flex key={entry.id} alignItems="center" mb={1}>
            <Box w="8px" h="8px" borderRadius="50%" bg={colors[index]} mr={2} />
            <Text fontSize="xs" color={'var(--chakra-colors-quredGray-300)'}>{`${entry.id} - ${
              entry.value
            } (${((entry.value / graphData?.total) * 100).toFixed(1)}%)`}</Text>
          </Flex>
        ))}
      </Box>
    </Flex>
  )
}

export default OfferingPieChart
