import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { Serie } from '@nivo/line'

const MyResponsiveLine = ({ data }: { data: Serie[] }) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 0,
      max: 'auto',
      stacked: true,
      reverse: false
    }}
    colors={{ datum: 'color' }}
    yFormat=" >-.2f"
    curve="natural"
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={null}
    enableGridX={false}
    enableGridY={false}
    enablePoints={false}
    isInteractive={false}
  />
)

export default MyResponsiveLine
