import React, { useRef, useEffect, useState, FC } from 'react'
import { StatsTileWithGraph } from '@components/Dashboard/Tile'
import { TestsHistogram } from '@components/Dashboard/Charts/Bar'
import { GridItem } from '@chakra-ui/react'
import { useAppContext } from '@contexts/AppProvider'
import { BarDatum } from '@nivo/bar'
import { DashboardGridItem } from '@types'

interface DataItem {
  [key: string]: number | string
}

const TestByProfile: FC<DashboardGridItem> = ({ colSpan, rowSpan, data }) => {
  const { demo } = useAppContext()

  let demoData = [] as BarDatum[]

  const keys = demo
    ? [
        'Bowel Cancer Screening',
        'Vital Vitamins',
        'Organ Function',
        'Cardiovascular Health',
        'Fertility Screening',
        'Cancer Screening'
      ]
    : data.reduce((acc: any, item: any) => {
        const keys = Object.keys(item).filter(
          (key) => key !== 'month' && key !== 'total' && !key.includes('Color')
        )
        return new Set([...acc, ...keys])
      }, new Set())

  if (demo) {
    demoData = data.map((item: DataItem) => {
      const newItem = { ...item } as DataItem
      keys.forEach((key: string | number) => {
        newItem[key] = Math.floor(Math.random() * 100)
      })
      return newItem
    })
  }
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [wrapperWidth, setWrapperWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setWrapperWidth(wrapperRef.current?.clientWidth || 0)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setWrapperWidth(wrapperRef.current?.clientWidth || 0)
  }, [])

  return (
    <GridItem colSpan={colSpan} rowSpan={rowSpan} ref={wrapperRef} width="100%" maxH="300px">
      {data && (
        <StatsTileWithGraph
          label={
            <>
              Test by Profile
              <br />
              Month by Month
            </>
          }
          icon={{ name: 'vial', size: 32 }}
          graph={
            <TestsHistogram
              data={demo ? demoData : data}
              keys={[...keys]}
              wrapperWidth={wrapperWidth}
              indexBy="month"
            />
          }
        />
      )}
    </GridItem>
  )
}

export default TestByProfile
