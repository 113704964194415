import React from 'react'
import { Flex, Grid } from '@chakra-ui/react'
import {
  EmployeesTotal,
  SignUpTotal,
  EmployeesTestedNumber,
  EmployeesAvaliableTests,
  CompletedTests,
  TestByProfile,
  TestPopulationDivergedBarContainer,
  TestByOffering
} from '@components/Dashboard/Stats'

interface DashboardProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any // TODO: type this
}

const Dashboard = ({ data }: DashboardProps) => {
  const { completedTests, userInsights, testsByProfile, testInsights, populationInsights } = data
  return (
    <Flex flexDirection="column" gap={4} width="100%">
      <Grid templateColumns="repeat(4, 1fr)" templateRows="repeat(1, 1fr)" gap={4} minH="184px">
        <EmployeesTotal stat={userInsights ? userInsights.total : 0} />
        <SignUpTotal stat={userInsights ? userInsights.verified : 0} />
        <EmployeesTestedNumber stat={userInsights ? userInsights.tested : 0} />
        <EmployeesAvaliableTests stat={userInsights ? userInsights.inProgress : 0} />
      </Grid>
      <Grid
        templateColumns="repeat(2, 1fr)"
        templateRows="repeat(1, 1fr)"
        gap={4}
        minH="300px"
        maxH="300px"
      >
        <TestByOffering data={userInsights} />
        <CompletedTests trends={completedTests} total={testInsights?.data?.total} />
      </Grid>
      <Grid minH="300px" maxH="300px">
        <TestByProfile rowSpan={1} colSpan={2} data={testsByProfile} />
      </Grid>
      <Grid templateColumns="repeat(3, 1fr)" templateRows="repeat(3, 1fr)" gap={4}>
        <TestPopulationDivergedBarContainer colSpan={3} rowSpan={1} data={populationInsights} />
        {/* 
        //TODO: uncomment the following lines once data is available for the following charts
        <OutcomesByTest rowSpan={3} colSpan={1} />
        <OutcomesMonthbyMonthLineContainer />
        <OutcomesNormalAbnormal />
        <GridItem colSpan={2} rowSpan={1} display="flex" gap={4}>
          <EmployeesWithRiskFactors />
          <HighRiskFactorBarContainer />
        </GridItem> */}
      </Grid>
    </Flex>
  )
}

export default Dashboard
