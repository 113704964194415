import { FC } from 'react'
import { Button, Stack, Flex, GridItem } from '@chakra-ui/react'

export interface ButtonItem {
  id: string
  testName: string
  male: number
  female: number
  onClick?: () => void
}

interface Props {
  items: ButtonItem[]
  activeTestId: string
  setActiveTestId: (id: string) => void
}

const Circle = ({ bg }: { bg: string }) => (
  <Flex w="8px" h="8px" borderRadius="50%" bg={bg} mr="4px" />
)

export const ButtonRow: FC<Props> = ({ items, setActiveTestId, activeTestId }) => (
  <Stack w="100%" mr={2}>
    {items.map(({ id, testName, male, female }) => (
      <Button
        key={testName}
        variant={activeTestId === id ? 'solid' : 'ghost'}
        colorScheme="quredBlue"
        size="sm"
        color={activeTestId === id ? 'white' : 'gray.900'}
        justifyContent="space-between"
        onClick={() => {
          setActiveTestId(id)
        }}
        display="grid"
        gridTemplateColumns="100px 120px"
        gridTemplateRows="repeat(1, 1fr)"
        textAlign="left"
      >
        <GridItem colSpan={1} mr={2}>
          {testName}
        </GridItem>
        <GridItem colSpan={1} display="flex" justifyContent="flex-end" gap={2}>
          <Flex alignItems="center" flex={1}>
            <Circle bg="quredOrange.500" />M {male}
          </Flex>
          <Flex alignItems="center" flex={1}>
            <Circle bg="quredPurple.500" />F {female}
          </Flex>
        </GridItem>
      </Button>
    ))}
  </Stack>
)

export default ButtonRow
