import React from 'react'
import { StatsTile } from '@components/Dashboard/Tile'

const EmployeesTotal = ({ stat }: { stat: number }) => {
  return (
    <StatsTile
      label={
        <>
          Total <br /> Employees
        </>
      }
      icon={{ name: 'team', size: 32 }}
      stat={stat || 0}
      // helperText="New employees"
      // trend="up"
    />
  )
}

export default EmployeesTotal
