import React, { FC } from 'react'
import { Flex, Text } from '@chakra-ui/react'

export interface TrendItem {
  trend?: 'up' | 'down'
  value?: number | string
  helperText?: string
  color?: string
}

const Trend: FC<TrendItem> = ({ value, trend, helperText, color }) => {
  const trendColor = color ? color : trend === 'up' ? 'quredBlue.500' : 'quredOrange.500'
  const helperTextColor = color || 'quredGray.300'

  return (
    <Flex flexDirection="column">
      {value && (
        <Text color={trendColor}>
          {trend === 'up' && '+'}
          {trend === 'down' && '-'}
          {value}
        </Text>
      )}
      {helperText && (
        <Text fontSize="xs" color={helperTextColor}>
          {helperText}
        </Text>
      )}
    </Flex>
  )
}

export default Trend
