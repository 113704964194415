import { FC, SVGProps } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { DefaultRawDatum, PieLayer } from '@nivo/pie'
import { RadialBarLayerId, RadialBarCustomLayer } from '@nivo/radial-bar'
import { RADIAL_CHART_MAIN_BAR_ID } from '@constants'

export const CustomTooltip: FC<{
  label: string
  color: string
  padding?: number | string | number[] | string[]
}> = ({ label, color, padding }) => (
  <Flex
    padding={padding || [1, 2]}
    color="quredGray.500"
    background="quredGray.900"
    fontSize="xs"
    borderRadius="lg"
    display="flex"
    alignItems="center"
    gap={2}
  >
    <Flex
      width="8px"
      height="8px"
      borderRadius="50%"
      backgroundColor={color}
      border="1px solid white"
    />
    <Text>{label}</Text>
  </Flex>
)

export const CentricLabel: FC<{ x: number; y: number; value: string | number }> = ({
  x,
  y,
  value
}) => (
  <text
    x={x}
    y={y}
    textAnchor="middle"
    dominantBaseline="central"
    style={{
      fontSize: 'var(--chakra-fontSizes-4xl)',
      fontWeight: 600
    }}
  >
    {value}
  </text>
)

export const AxisTick: FC<{
  x: number
  y: number
  value: string
  dominantBaseline?: number | string
  textAnchor?: string
}> = ({ x, y, value, dominantBaseline, textAnchor }) => (
  <g transform={`translate(${x}, ${y})`}>
    <text
      dominantBaseline={dominantBaseline}
      textAnchor={textAnchor}
      style={{
        fill: 'var(--chakra-colors-quredGray-300)',
        fontSize: '0.70rem'
      }}
    >
      {value}
    </text>
  </g>
)

export const CenteredMetricPie: PieLayer<DefaultRawDatum> = ({ dataWithArc, centerX, centerY }) => {
  let total = 0

  dataWithArc.forEach((datum) => {
    total += datum.value
  })

  return <CentricLabel x={centerX} y={centerY} value={total} />
}

export const CenteredMetricRadialPie: RadialBarLayerId | RadialBarCustomLayer = ({
  center,
  bars
}) => {
  const total = bars.reduce(
    (acc, bar) => (bar.groupId === RADIAL_CHART_MAIN_BAR_ID ? acc + bar.value : 0),
    0
  )
  return <CentricLabel x={center[0]} y={center[1]} value={total} />
}

export const CustomBarSVG: FC<SVGProps<SVGElement>> = ({
  x,
  y,
  width,
  height,
  color,
  rx,
  onMouseEnter,
  onMouseLeave,
  onClick,
  onFocus,
  onBlur
}) => {
  return (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <rect width={width} height={height} fill={color} rx={rx} />
    </g>
  )
}
